const INITIAL_STATE = {
  events: null,
};

const applySetEvents = (state, action) => ({
  ...state,
  events: action.events,
});

const applySetEvent = (state, action) => ({
  ...state,
  events: {
    ...state.events,
    [action.uid]: action.event,
  },
});

function eventReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'EVENTS_SET': {
      return applySetEvents(state, action);
    }
    case 'EVENT_SET': {
      return applySetEvent(state, action);
    }
    default:
      return state;
  }
}

export default eventReducer;
