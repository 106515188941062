import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import ComingSoon from '../ComingSoon';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
  <Router>
    <Route path={ROUTES.LANDING} component={ComingSoon} />
  </Router>
);

export default withAuthentication(App);
