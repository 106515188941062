import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.emailAuthProvider = app.auth.EmailAuthProvider; // must be before app.auth()
    this.auth = app.auth();
    this.db = app.firestore();
    this.serverValue = app.firestore.FieldValue;

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        // fetch user profile and roles concurrently
        const dbPromises = [
          this.user(authUser.uid).get(),
          this.roles(authUser.uid).get(),
          this.membership(authUser.uid).get(),
        ];
        Promise.all(dbPromises)
          .then(querySnapshots => {
            // resolve after both promises complete and merge
            const user = querySnapshots[0];
            const roles = querySnapshots[1];
            const memberships = querySnapshots[2];
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              roles: (roles.data() || {}),
              memberships: (memberships.data() || {}),
              ...user.data(),
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    },)

  // *** User API ***

  user = uid => this.db.collection('users').doc(uid);

  users = () => this.db.collection('users');

  // *** Roles API ***

  roles = uid => this.db.collection('roles').doc(uid);

  // *** Events API ***

  event = uid => this.db.collection('events').doc(uid);

  events = () => this.db.collection('events');

  // *** Entries API ***

  entry = uid => this.db.collection('entries').doc(uid);

  entries = () => this.db.collection('entries');

  // *** Memberships API ***

  membership = uid => this.db.collection('memberships').doc(uid);

}

export default Firebase;
