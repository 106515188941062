import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import eventReducer from './event';
import entryReducer from './entry';

const appReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  eventState: eventReducer,
  entryState: entryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
