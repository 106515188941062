const INITIAL_STATE = {
  entries: null,
};

const applySetEntries = (state, action) => ({
  ...state,
  entries: action.entries,
});

const applySetEntry = (state, action) => ({
  ...state,
  entries: {
    ...state.entries,
    [action.uid]: action.entry,
  },
})

function entryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'ENTRIES_SET': {
      return applySetEntries(state, action);
    }
    case 'ENTRY_SET': {
      return applySetEntry(state, action);
    }
    default:
      return state;
  }
}

export default entryReducer;
